import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import GenericPreview from "../../components/generic-preview/generic-preview"
import IconExpansionSection from "../../components/icon-expansion-section/icon-expansion-section"
import OverviewSlider from "../../components/overview-slider/overview-slider"
import PageHeader from "../../components/page-header/page-header"
import TextWithImageHero from "../../components/page-heros/text-with-image-hero"
import RichText from "../../components/rich-text/rich-text"
import SEO from "../../components/seo"
import { SustainabilityReport } from "../../components/sustainablilty/sustainability-report/sustainability-report"
import Tile from "../../components/tile/tile"
import TileContentSpacer from "../../components/tile/tile-content-spacer/tile-content-spacer"
import useNav from "../../hooks/useNav"
import { removeLocaleFromUri } from "../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../i18n/translate"
import {
  dynamicImageWithGatsbyImage,
  dynamicImageWithPublicUrl,
} from "../../util/dynamic-image"
import "./sustainability-at-munzing.sass"

const SustainabilityPage = ({ data, location }) => {
  const { t } = useTranslation()

  const rootNav = useNav({
    path: removeLocaleFromUri(location.pathname),
    getRootLevel: true,
  })

  const relatedPages = [
    {
      titleTranslationKey: "SUSTAINABILITY-ENVIRONMENT-PAGE_TITLE",
      descriptionTranslationKey: "SUSTAINABILITY-ENVIRONMENT-META_DESCRIPTION",
      image: data.environment?.image?.image?.localFile?.childImageSharp,
      buttonTextTranslationKey:
        "SUSTAINABILITY-ENVIRONMENT-MORE_ABOUT_LINK_TITLE",
      link: "/sustainability/environment/",
    },
    {
      titleTranslationKey: "SUSTAINABILITY-ECONOMY-PAGE_TITLE",
      descriptionTranslationKey: "SUSTAINABILITY-ECONOMY-META_DESCRIPTION",
      image: data.economy?.image?.image?.localFile?.childImageSharp,
      buttonTextTranslationKey: "SUSTAINABILITY-ECONOMY-MORE_ABOUT_LINK_TITLE",
      link: "/sustainability/economic/",
    },
    {
      titleTranslationKey: "SUSTAINABILITY-SOCIAL-PAGE_TITLE",
      descriptionTranslationKey: "SUSTAINABILITY-SOCIAL-META_DESCRIPTION",
      image: data.social?.image?.image?.localFile?.childImageSharp,
      buttonTextTranslationKey: "SUSTAINABILITY-SOCIAL-MORE_ABOUT_LINK_TITLE",
      link: "/sustainability/social/",
    },
  ]

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("SUSTAINABILITY-PAGE_TITLE")}
        descriptionTranslatedString={t("SUSTAINABILITY-HERO-DESCRIPTION")}
      />
      <PageHeader
        container={"secondary"}
        levelThreeTitle={t("SUSTAINABILITY-PAGE_TITLE")}
        levelTwoTitle={t("SUSTAINABILITY-PAGE_TITLE")}
        levelOneTitle={t(rootNav.translationKey)}
      />

      <TextWithImageHero textTranslationKey={"SUSTAINABILITY-HERO-DESCRIPTION"}>
        <div className={"sustainability__hero-image"}>
          <GatsbyImage
            image={dynamicImageWithGatsbyImage(data.hero)}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          ></GatsbyImage>
        </div>
      </TextWithImageHero>

      <div
        className="_fp-global-container-secondary"
        style={{ marginBlock: "var(--_fp-vertical-grid-gap-size)" }}
      >
        <Tile
          tileSize={"m"}
          titleTranslatedString={t("SUSTAINABILITY-REPORT-TITLE")}
        >
          <SustainabilityReport
            reports={[
              {
                url: "/documents/Technischer_Nachhaltigkeitsreport_2021-2023.pdf",
                id: "technical_sustainability_report_download",
                image: data.technicalSustainabilityReport,
                downloadLabelTranslated: t(
                  "SUSTAINABILITY-REPORT-TECHNICAL-REPORT-LINK-TITLE",
                ),
              },
              {
                url: "/documents/MÜNZING Sustainability Report 2022.pdf",
                id: "sustainability_report_2022_download",
                image: data.sustainabilityReport22,
                downloadLabelTranslated: t(
                  "SUSTAINABILITY-REPORT-2022-LINK-TITLE",
                ),
              },
            ]}
          />
        </Tile>
      </div>

      <div
        className="_fp-global-container-secondary"
        style={{ overflow: "hidden" }}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-6 _fp-col-12sp _fp-col-12p _fp-col-12st">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t("SUSTAINABILITY-OBJECTIVES-TITLE")}
            >
              <RichText
                ulCheckmarks={true}
                textTranslationKey={"SUSTAINABILITY-OBJECTIVES-DESCRIPTION"}
              ></RichText>
            </Tile>
          </div>

          <div className="_fp-col-6 _fp-col-12sp _fp-col-12p _fp-col-12st">
            <Tile
              tileSize={"m"}
              titleTranslatedString={t(
                "SUSTAINABILITY-ECOVADIS_CERTIFICATION-TITLE",
              )}
            >
              <a
                href="/documents/MUENZING_CHEMIE_GMBH_(GROUP)_EcoVadis_Rating_Certificate_2023_10_18.pdf"
                target={"_blank"}
                style={{ display: "flex", gap: "2rem", alignItems: "center" }}
              >
                <img
                  style={{ width: "8rem" }}
                  src="/images/hero/ecovadis_medal_2023.png"
                  alt=""
                />
                <img
                  style={{ width: "10rem" }}
                  src="/images/hero/ecovadis_certificate_2023.jpg"
                  alt=""
                  className={"shadow"}
                />
              </a>
              <TileContentSpacer />
              <RichText
                textTranslationKey={
                  "SUSTAINABILITY-ECOVADIS_CERTIFICATION-DESCRIPTION"
                }
              ></RichText>
            </Tile>
          </div>
          <div className="_fp-col-12">
            <IconExpansionSection
              titleTranslatedString={t(
                "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-TITLE",
              )}
              descriptionTranslatedHtml={t(
                "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-DESCRIPTION",
              )}
              sections={[
                {
                  image: "/images/sustainability/E_SDG_Icons-02.jpg",
                  title: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-02-TITLE",
                  ),
                  description: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-02-DESCRIPTION",
                  ),
                },
                {
                  image: "/images/sustainability/E_SDG_Icons-03.jpg",
                  title: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-03-TITLE",
                  ),
                  description: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-03-DESCRIPTION",
                  ),
                },
                {
                  image: "/images/sustainability/E_SDG_Icons-06.jpg",
                  title: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-06-TITLE",
                  ),
                  description: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-06-DESCRIPTION",
                  ),
                },
                {
                  image: "/images/sustainability/E_SDG_Icons-07.jpg",
                  title: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-07-TITLE",
                  ),
                  description: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-07-DESCRIPTION",
                  ),
                },
                {
                  image: "/images/sustainability/E_SDG_Icons-12.jpg",
                  title: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-12-TITLE",
                  ),
                  description: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-12-DESCRIPTION",
                  ),
                },
                {
                  image: "/images/sustainability/E_SDG_Icons-13.jpg",
                  title: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-13-TITLE",
                  ),
                  description: t(
                    "SUSTAINABILITY-SUSTAINABILITY_DEVELOPMENT_GOALS-13-DESCRIPTION",
                  ),
                },
              ]}
            ></IconExpansionSection>
          </div>

          <div className="_fp-col-12">
            <OverviewSlider
              title={t("SUSTAINABILITY-SUSTAINABILITY_MORE_PAGES_TITLE")}
              size={"products"}
            >
              {relatedPages.map((page, index) => (
                <GenericPreview
                  image={page.image}
                  imageAltTranslationKey={page.titleTranslationKey}
                  key={`related-pages-${index}`}
                  titleTranslationKey={page.titleTranslationKey}
                  descriptionTranslationKey={page.descriptionTranslationKey}
                  linkToProductsPagePath={page.link}
                  linkToProductsPageTextTranslationKey={
                    page.buttonTextTranslationKey
                  }
                  linkToDetailPagePath={page.link}
                ></GenericPreview>
              ))}
            </OverviewSlider>
          </div>
        </div>
      </div>
    </>
  )
}

export default SustainabilityPage

export const query = graphql`
  {
    hero: strapiDynamicImage(uid: { eq: "SUSTAINABILITY_PAGE_IMAGE" }) {
      ...DynamicImageWithGatsbyImage
    }
    sustainabilityReport22: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_PAGE_REPORT_2022_EN" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    technicalSustainabilityReport: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_PAGE_TECHNICAL_SUSTAINABILITY_REPORT" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    environment: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ENVIRONMENT_PAGE_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    economy: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_ECONOMY_PAGE_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    social: strapiDynamicImage(
      uid: { eq: "SUSTAINABILITY_SOCIAL_PAGE_IMAGE" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
