import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../../util/dynamic-image"
import trackCustomEvent from "../../../util/trackCustomEvent"
import {
  getDynamicPdfFileUrl,
  getPdfFileItemForLanguage,
} from "../../dynamic-pdf-file/dynamic-pdf-file"

export const SustainabilityReport = (props) => {
  const { t } = useTranslation()

  return (
    <div className="_fp-grid _fp-grid--gap">
      <div className="_fp-col-7 _fp-col-12sp _fp-col-12p _fp-col-12st _fp-global-regular-font-formatting">
        <p
          dangerouslySetInnerHTML={{ __html: t("SUSTAINABILITY-REPORT-TEXT") }}
        ></p>
        {props.reports.map((report, i) => {
          return (
            <>
              <a
                target={"_blank"}
                href={report.url}
                onClick={() =>
                  trackCustomEvent({
                    category: "sustainability_report",
                    action: report.id,
                  })
                }
              >
                {report.downloadLabelTranslated}
              </a>
              <br />
            </>
          )
        })}
      </div>
      <div
        style={{ display: "flex", gap: "1rem", alignItems: "start" }}
        className="_fp-col-5 _fp-col-12sp _fp-col-12p _fp-col-12st"
      >
        {props.reports.map((report, index) => {
          return (
            <a
              key={index}
              target={"_blank"}
              href={report.url}
              onClick={() =>
                trackCustomEvent({
                  category: "sustainability_report",
                  action: report.id,
                })
              }
              className={"shadow"}
              style={{ display: "block", flex: 1, maxWidth: "20rem" }}
            >
              <GatsbyImage
                alt={report.downloadLabelTranslated}
                image={dynamicImageWithGatsbyImage(report.image)}
                style={{ height: "100%" }}
                imgStyle={{ objectFit: "contain" }}
              ></GatsbyImage>
            </a>
          )
        })}
      </div>
    </div>
  )
}
